<template>
    <svg v-if="icon === 'BTC'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" :width="width" :height="height">
        <g transform="translate(-289.60744,-341.50536)">
            <g transform="matrix(0.61129216,0,0,0.61129216,170.80346,315.53734)">
                <path style="fill:#F7931A;" d="M1019.337,562.575c-55.934,224.349-283.161,360.884-507.536,304.938    c-224.283-55.933-360.819-283.174-304.86-507.51C262.848,135.628,490.076-0.921,714.386,55.012    C938.747,110.946,1075.27,338.213,1019.337,562.575z"/>
                <path style="fill:#FFFFFF;" d="M475.38,531.795c-2.277,5.654-8.048,14.134-21.057,10.914c0.459,0.668-33.397-8.336-33.397-8.336    l-22.81,52.596l59.793,14.907c11.124,2.787,22.026,5.705,32.757,8.454l-19.016,76.349l45.896,11.451l18.832-75.538    c12.538,3.402,24.708,6.543,36.617,9.501l-18.767,75.184l45.949,11.451l19.015-76.205c78.352,14.827,137.268,8.847,162.068-62.019    c19.984-57.058-0.994-89.973-42.218-111.435c30.022-6.923,52.635-26.671,58.668-67.463l0,0    c8.336-55.724-34.092-85.68-92.106-105.664l18.819-75.486l-45.949-11.451l-18.321,73.496c-12.079-3.01-24.486-5.85-36.813-8.664    l18.452-73.981l-45.922-11.451l-18.832,75.459c-9.999-2.277-19.813-4.529-29.341-6.897l0.052-0.236l-63.367-15.822l-12.224,49.076    c0,0,34.092,7.813,33.372,8.297c18.61,4.646,21.973,16.96,21.411,26.724L475.38,531.795z M692.727,548.821    c-14.199,57.058-110.27,26.212-141.417,18.478l25.232-101.148C607.688,473.924,707.567,489.314,692.727,548.821z M706.939,400.782    c-12.956,51.903-92.917,25.533-118.855,19.068l22.876-91.74C636.898,334.575,720.432,346.641,706.939,400.782z"/>
            </g>
        </g>
    </svg>

    <svg v-else viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height">
        <linearGradient id="linear-gradient" gradientUnits="userSpaceOnUse" x1="100" x2="100" y1="200">
            <stop offset="0" stop-color="#3e5ebd"/>
            <stop offset="1" stop-color="#94aeff"/>
        </linearGradient>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Gradiants">
                <g id="Ethereum_ETH_" data-name="Ethereum (ETH)">
                    <circle cx="100" cy="100" fill="url(#linear-gradient)" r="100"/>
                    <path d="m99.99 127.24-33.6-19.85 33.6 47.36 33.63-47.36z" fill="#fff"/>
                    <path d="m133.6 101.02-33.61 19.86-33.61-19.86 33.61-55.77z" fill="#fff"/>
                    <g fill="#c1ccf7">
                        <path d="m133.6 101.02-33.61-15.28v-40.49z"/>
                        <path d="m133.62 107.39-33.63 47.36v-27.51z"/>
                        <path d="m99.99 85.74v35.14l-33.6-19.86z"/>
                    </g>
                    <path d="m133.6 101.02-33.61 19.86v-35.14z" fill="#8198ee"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
	name: "CryptoCurrencyIcon",
	props: {
		height: {
			type: Number,
			default: 24
		},
		width: {
			type: Number,
			default: 24
		},
        icon: {
            type: String,
            required: true
        }
  	}
}
</script>
